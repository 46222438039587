import axios from "axios";

const BE_URL = process.env.REACT_APP_BASE_API_URL;
console.log("BE_URL", BE_URL);
const headers = (token = null, lang = "PL") => {
  const h = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Lang: lang,
  };
  if (token) {
    h.Authorization = `Bearer ${token}`;
  }
  return h;
};

const getLocalAccessToken = () => {
  const tokens = JSON.parse(window.localStorage.getItem("tokens"));
  return tokens.access;
};

const getLocalRefreshToken = () => {
  const tokens = JSON.parse(window.localStorage.getItem("tokens"));
  return tokens.refresh;
};

const getLocalLang = () => {
  return window.localStorage.getItem("lang");
};

const api = {
  auth: {
    getToken: (email, password) => {
      console.log("getToken()");
      return axios.post(
        `${BE_URL}/auth/jwt/create/`,
        {
          email: `${email}`,
          password: `${password}`,
        },
        {
          headers: headers(),
        }
      );
    },
    refreshToken: () => {
      return axios.post(
        `${BE_URL}/auth/jwt/refresh/`,
        { refresh: getLocalRefreshToken() },
        { headers: headers() }
      );
    },
  },
  users: {
    getMe: () => {
      return axios.get(`${BE_URL}/users/me/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    getUsers: (page, pageSize, order_by, queryFilters) => {
      return axios.get(
        `${BE_URL}/users/?page=${page}&size=${pageSize}&order_by=${order_by}&${queryFilters}`,
        {
          headers: headers(getLocalAccessToken(), getLocalLang()),
        }
      );
    },
  },
  projects: {
    stats: (projectId, offsetDays) => {
      let h = headers(getLocalAccessToken(), getLocalLang());
      h["Offset"] = offsetDays;
      return axios.get(`${BE_URL}/projects/${projectId}/stats/`, {
        headers: h,
      });
    },
    jobs: (projectId, offsetDays) => {
      let h = headers(getLocalAccessToken(), getLocalLang());
      h["Offset"] = offsetDays;
      return axios.get(`${BE_URL}/projects/${projectId}/jobs/`, {
        headers: h,
      });
    },
    deleteProject: (projectId) => {
      return axios.delete(`${BE_URL}/projects/${projectId}/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    createProject: (payload) => {
      return axios.post(`${BE_URL}/projects/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    editProject: (projectId, payload) => {
      return axios.put(`${BE_URL}/projects/${projectId}/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    toggleFavorite: (projectId) => {
      return axios.post(
        `${BE_URL}/projects/${projectId}/toggle_fav/`,
        {},
        {
          headers: headers(getLocalAccessToken(), getLocalLang()),
        }
      );
    },
    tokens: (projectId) => {
      return axios.get(`${BE_URL}/projects/${projectId}/tokens/?size=100`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    createToken: (projectId) => {
      return axios.post(
        `${BE_URL}/projects/${projectId}/create_token/`,
        {},
        {
          headers: headers(getLocalAccessToken(), getLocalLang()),
        }
      );
    },
    deleteToken: (projectId, tokenId) => {
      return axios.post(
        `${BE_URL}/projects/${projectId}/delete_token/`,
        { token: tokenId },
        {
          headers: headers(getLocalAccessToken(), getLocalLang()),
        }
      );
    },
    project: (id) => {
      return axios.get(`${BE_URL}/projects/${id}/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    favouriteProjects: (page = 1, size = 10, order = "-id", filters = {}) => {
      let url = `${BE_URL}/projects/fav/?page=${page}&size=${size}&order_by=${order}`;
      for (const [key, value] of Object.entries(filters)) {
        url += `&${key}=${value}`;
      }
      return axios.get(url, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    projects: (page = 1, size = 10, order = "-id", filters = {}) => {
      let url = `${BE_URL}/projects/?page=${page}&size=${size}&order_by=${order}`;
      for (const [key, value] of Object.entries(filters)) {
        url += `&${key}=${value}`;
      }
      return axios.get(url, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    tags: () => {
      return axios.get(`${BE_URL}/projects/tags/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
  },
  reports: {
    report: (id) => {
      return axios.get(`${BE_URL}/reports/${id}/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    reports: (page = 1, size = 10, order = "-id", filters = {}) => {
      let url = `${BE_URL}/reports/?page=${page}&size=${size}&order_by=${order}`;
      for (const [key, value] of Object.entries(filters)) {
        url += `&${key}=${value}`;
      }
      return axios.get(url, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    tags: () => {
      return axios.get(`${BE_URL}/reports/tags/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
  },
};

export default api;
