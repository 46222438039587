export const TRANSLATIONS = {
  REPORT_COUNT: {
    key: "REPORT_COUNT",
    val: {
      PL: "Liczba raportów",
      EN: "Report count",
    },
  },

  GO_TO_CI_PIPELINE: {
    key: "GO_TO_CI_PIPELINE",
    val: {
      PL: "Przejdź do pipeline CI",
      EN: "Go to CI pipeline",
    },
  },

  CHARTS_FROM_DAYS: {
    key: "CHARTS_FROM_DAYS",
    val: {
      PL: "Wykresy - dni wstecz",
      EN: "Charts - days ago",
    },
  },

  APPLY: {
    key: "APPLY",
    val: {
      PL: "Zastosuj",
      EN: "Apply",
    },
  },

  BACK: {
    key: "BACK",
    val: {
      PL: "Powrót",
      EN: "Back",
    },
  },

  OFFSET_DAYS: {
    key: "OFFSET_DAYS",
    val: {
      PL: "Dni wstecz",
      EN: "Days ago",
    },
  },

  JOBS: {
    key: "JOBS",
    val: {
      PL: "Joby",
      EN: "Jobs",
    },
  },

  TESTS: {
    key: "TESTS",
    val: {
      PL: "Testy",
      EN: "Tests",
    },
  },

  JOB_COUNT: {
    key: "JOB_COUNT",
    val: {
      PL: "Liczba jobów",
      EN: "Job count",
    },
  },

  FROM_TO: {
    key: "FROM_TO",
    val: {
      PL: "Od - do",
      EN: "From - to",
    },
  },

  GO_TO_PROJECT: {
    key: "GO_TO_PROJECT",
    val: {
      PL: "Przejdź do projektu",
      EN: "Go to project",
    },
  },

  REPORT: {
    key: "REPORT",
    val: {
      PL: "Raport",
      EN: "Report",
    },
  },

  SKIPPED: {
    key: "SKIPPED",
    val: {
      PL: "Pominięte",
      EN: "Skipped",
    },
  },

  ALL: {
    key: "ALL",
    val: {
      PL: "Wszystkie",
      EN: "All",
    },
  },

  FAILED: {
    key: "FAILED",
    val: {
      PL: "Niepowodzenie",
      EN: "Failed",
    },
  },

  PASSED: {
    key: "PASSED",
    val: {
      PL: "Sukces",
      EN: "Passed",
    },
  },

  SELECT_STATUS: {
    key: "SELECT_STATUS",
    val: {
      PL: "Wybierz status",
      EN: "Select status",
    },
  },

  SELECT_PROJECT: {
    key: "SELECT_PROJECT",
    val: {
      PL: "Wybierz projekt",
      EN: "Select project",
    },
  },

  GO_TO_REPORTS: {
    key: "GO_TO_REPORTS",
    val: {
      PL: "Przejdź do raportów",
      EN: "Go to reports",
    },
  },

  DELETE_TOKEN_CONFIRMATION_MESSAGE: {
    key: "DELETE_TOKEN_CONFIRMATION_MESSAGE",
    val: {
      PL: "Czy na pewno chcesz usunąć token?",
      EN: "Are you sure you want to delete the token?",
    },
  },

  FAVOURITE_PROJECTS: {
    key: "FAVOURITE_PROJECTS",
    val: {
      PL: "Ulubione projekty",
      EN: "Favourite projects",
    },
  },

  MANAGE_USERS: {
    key: "MANAGE_USERS",
    val: {
      PL: "Zarządzaj użytkownikami",
      EN: "Manage users",
    },
  },

  USERS: {
    key: "USERS",
    val: {
      PL: "Użytkownicy",
      EN: "Users",
    },
  },

  SAVE: {
    key: "SAVE",
    val: {
      PL: "Zapisz",
      EN: "Save",
    },
  },

  EDIT_PROJECT: {
    key: "EDIT_PROJECT",
    val: {
      PL: "Edytuj projekt",
      EN: "Edit project",
    },
  },

  DELETE_PROJECT: {
    key: "DELETE_PROJECT",
    val: {
      PL: "Usuń projekt",
      EN: "Delete project",
    },
  },

  DELETE_PROJECT_CONFIRMATION_MESSAGE: {
    key: "DELETE_PROJECT_CONFIRMATION_MESSAGE",
    val: {
      PL: "Czy na pewno chcesz usunąć projekt? Powiązane z nim dane zostaną utracone.",
      EN: "Are you sure you want to delete the project? Related data will be lost.",
    },
  },

  FIELD_REQUIRED: {
    key: "FIELD_REQUIRED",
    val: {
      PL: "Pole wymagane",
      EN: "Field required",
    },
  },

  PRIVATE: {
    key: "PRIVATE",
    val: {
      PL: "Prywatny",
      EN: "Private",
    },
  },

  CREATE: {
    key: "CREATE",
    val: {
      PL: "Utwórz",
      EN: "Create",
    },
  },

  ADD_PROJECT: {
    key: "ADD_PROJECT",
    val: {
      PL: "Dodaj projekt",
      EN: "Add project",
    },
  },

  DELETE: {
    key: "DELETE",
    val: {
      PL: "Usuń",
      EN: "Delete",
    },
  },

  OPEN_CI_JOB: {
    key: "OPEN_CI_JOB",
    val: {
      PL: "Otwórz job CI",
      EN: "Open CI job",
    },
  },

  OPEN_REPORT: {
    key: "OPEN_REPORT",
    val: {
      PL: "Otwórz raport",
      EN: "Open report",
    },
  },

  ADD_FAVORITE: {
    key: "ADD_FAVORITE",
    val: {
      PL: "Dodaj do ulubionych",
      EN: "Add to favourites",
    },
  },

  REMOVE_FAVORITE: {
    key: "REMOVE_FAVORITE",
    val: {
      PL: "Usuń z ulubionych",
      EN: "Remove from favourites",
    },
  },

  LOADING: {
    key: "LOADING",
    val: {
      PL: "Ładowanie...",
      EN: "Loading...",
    },
  },

  TOKEN_ADDED_MESSAGE: {
    key: "TOKEN_ADDED_MESSAGE",
    val: {
      PL: "Utworzono token",
      EN: "Token created",
    },
  },

  CANCEL: {
    key: "CANCEL",
    val: {
      PL: "Anuluj",
      EN: "Cancel",
    },
  },

  ADD_TOKEN_CONFIRMATION_MESSAGE: {
    key: "ADD_TOKEN_CONFIRMATION_MESSAGE",
    val: {
      PL: "Czy na pewno chcesz dodać nowy token?",
      EN: "Are you sure you want to add a new token?",
    },
  },

  CONFIRM: {
    key: "CONFIRM",
    val: {
      PL: "Potwierdź",
      EN: "Confirm",
    },
  },

  ADD_TOKEN: {
    key: "ADD_TOKEN",
    val: {
      PL: "Dodaj token",
      EN: "Add token",
    },
  },

  TOKENS: {
    key: "TOKENS",
    val: {
      PL: "Tokeny",
      EN: "Tokens",
    },
  },

  PROJECT_DETAILS: {
    key: "PROJECT_DETAILS",
    val: {
      PL: "Szczegóły projektu",
      EN: "Project details",
    },
  },

  CREATED_DATE: {
    key: "CREATED_DATE",
    val: {
      PL: "Data utworzenia",
      EN: "Created date",
    },
  },

  ACTIONS: {
    key: "ACTIONS",
    val: {
      PL: "Akcje",
      EN: "Actions",
    },
  },

  STATUS: {
    key: "STATUS",
    val: {
      PL: "Status",
      EN: "Status",
    },
  },

  SUMMARY: {
    key: "SUMMARY",
    val: {
      PL: "Podsumowanie",
      EN: "Summary",
    },
  },

  PROJECT: {
    key: "PROJECT",
    val: {
      PL: "Projekt",
      EN: "Project",
    },
  },

  REPORTS_LIST: {
    key: "REPORTS_LIST",
    val: {
      PL: "Raporty",
      EN: "Reports",
    },
  },

  SHOW_FILTERS: {
    key: "SHOW_FILTERS",
    val: {
      PL: "Pokaż filtry",
      EN: "Show filters",
    },
  },

  HIDE_FILTERS: {
    key: "HIDE_FILTERS",
    val: {
      PL: "Ukryj filtry",
      EN: "Hide filters",
    },
  },

  LOGOUT: {
    key: "LOGOUT",
    val: {
      PL: "Wyloguj",
      EN: "Logout",
    },
  },

  USER_PROFILE: {
    key: "USER_PROFILE",
    val: {
      PL: "Profil użytkownika",
      EN: "User profile",
    },
  },

  SETTINGS: {
    key: "SETTINGS",
    val: {
      PL: "Ustawienia",
      EN: "Settings",
    },
  },

  REPORTS: {
    key: "REPORTS",
    val: {
      PL: "Raporty",
      EN: "Reports",
    },
  },

  DASHBOARD: {
    key: "DASHBOARD",
    val: {
      PL: "Panel",
      EN: "Dashboard",
    },
  },

  COMMONS__EMPTY_LIST_TITLE: {
    key: "COMMONS__EMPTY_LIST_TITLE",
    val: {
      EN: "No data",
      PL: "Brak danych",
    },
  },

  SORTING_ASCENDING_LABEL: {
    key: "SORTING_ASCENDING_LABEL",
    val: {
      EN: "Sort ascending",
      PL: "Sortuj rosnąco",
    },
  },

  SORTING_DESCENDING_LABEL: {
    key: "SORTING_DESCENDING_LABEL",
    val: {
      EN: "Sort descensing",
      PL: "Sortuj malejąco",
    },
  },

  SORTING_CANCEL_LABEL: {
    key: "SORTING_CANCEL_LABEL",
    val: {
      EN: "Cancel sort",
      PL: "Usuń sortowanie",
    },
  },

  DESCRIPTION: {
    key: "DESCRIPTION",
    val: {
      EN: "Description",
      PL: "Opis",
    },
  },

  FAVOURITE: {
    key: "FAVOURITE",
    val: {
      EN: "Favourite",
      PL: "Ulubione",
    },
  },

  TAGS_MATCH_ALL: {
    key: "TAGS_MATCH_ALL",
    val: {
      EN: "Match all tags",
      PL: "Pasujące wszystkie tagi",
    },
  },

  TAGS_MATCH_ANY: {
    key: "TAGS_MATCH_ANY",
    val: {
      EN: "Match any tag",
      PL: "Pasujące dowolny tag",
    },
  },

  YOUR_FAVOURITE: {
    key: "YOUR_FAVOURITE",
    val: {
      EN: "In your favourites",
      PL: "W twoich ulubionych",
    },
  },

  SEARCH: {
    key: "SEARCH",
    val: {
      EN: "Search",
      PL: "Szukaj",
    },
  },

  CLEAR: {
    key: "CLEAR",
    val: {
      EN: "Clear",
      PL: "Wyczyść",
    },
  },

  FILTERS: {
    key: "FILTERS",
    val: {
      EN: "Filters",
      PL: "Filtry",
    },
  },

  EMPTY_LIST_TITLE: {
    key: "EMPTY_LIST_TITLE",
    val: {
      EN: "No data",
      PL: "Brak danych",
    },
  },

  PROJECT_LIST: {
    key: "PROJECT_LIST",
    val: {
      PL: "Projekty",
      EN: "Projects",
    },
  },

  YOUR_FAV_PROJECTS: {
    key: "YOUR_FAV_PROJECTS",
    val: {
      PL: "Ulubione projekty",
      EN: "Your favourite projects",
    },
  },

  PROJECT_NAME: {
    key: "PROJECT_NAME",
    val: {
      PL: "Nazwa projektu",
      EN: "Project name",
    },
  },

  PER_PAGE: {
    key: "PER_PAGE",
    val: {
      PL: "na stronę",
      EN: "per page",
    },
  },

  TOTAL: {
    key: "TOTAL",
    val: {
      PL: "Razem",
      EN: "Total",
    },
  },

  ITEMS: {
    key: "ITEMS",
    val: {
      PL: "elementy",
      EN: "items",
    },
  },

  TITLE: {
    key: "TITLE",
    val: {
      PL: "Tytuł",
      EN: "Title",
    },
  },

  NAME: {
    key: "NAME",
    val: {
      PL: "Nazwa",
      EN: "Name",
    },
  },
  TAGS: {
    key: "TAGS",
    val: {
      PL: "Tagi",
      EN: "Tags",
    },
  },
};
