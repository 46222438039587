import t from "../translate";
import { TRANSLATIONS } from "../translate/translations";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import api from "../infra/api";
import { Col, Empty, Flex, Row, Table, Tag } from "antd";

export default function ReportDetailsPage({ user, setUser }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    console.log("fetch reprot details");
    // fetch project details
    setLoading(true);
    api.reports
      .report(id)
      .then((response) => {
        console.log("report details response", response);
        setTimeout(() => {
          setLoading(false);
          setReport(response.data);
        }, 500);
      })
      .catch((error) => {
        console.error("fetch report details error", error);
        setLoading(false);
        if (error.response.status === 401) {
          setUser(null);
          // TODO: if 403
        } else if (error.response.status === 404) {
          // project not found
          navigate("/404");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allTests = useMemo(() => {
    if (!report) return [];
    const result = [];
    const specs = report.data.results;
    console.log("specs", specs);
    specs.forEach((spec) => {
      const file = spec.file;
      const suites = spec.suites;
      suites.forEach((suite) => {
        const tests = suite.tests;
        const suiteTitle = suite.title;
        tests.forEach((test) => {
          result.push({
            file,
            suiteTitle,
            ...test,
          });
        });
      });
    });
    console.log("all tests", result);
    return result;
  }, [report]);
  const testsColumns = [
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      sorter: (a, b) => a.file.localeCompare(b.file),
    },
    {
      title: "Suite",
      dataIndex: "suiteTitle",
      key: "suiteTitle",
      sorter: (a, b) => a.suiteTitle.localeCompare(b.suiteTitle),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      sorter: (a, b) => a.state.localeCompare(b.state),
      render: (status) => {
        let color = "green";
        if (status === "failed") {
          color = "red";
        } else if (status === "skipped") {
          color = "grey";
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      sorter: (a, b) => a.duration - b.duration,
      render: (duration) => {
        return <span>{duration} ms</span>;
      },
    },
  ];
  return (
    <Row>
      <Col span={24}>
        {loading && <h1>{t(TRANSLATIONS.LOADING.key)}</h1>}
        {report && report.type === "MOCHAWESOME" && !loading && (
          <Row>
            <Col span={24}>
              <Flex
                gap="middle"
                align="center"
                justify="left"
                style={{ marginTop: 10 }}
              >
                <Link to={`/projects/${report.project}`}>
                  {t(TRANSLATIONS.GO_TO_PROJECT.key)}
                </Link>
                <Link target="_blank" to={`${report.ci_job_url}`}>
                  {t(TRANSLATIONS.GO_TO_CI_PIPELINE.key)}
                </Link>
              </Flex>
              <Flex gap="middle" align="center" justify="space-between">
                <div>
                  <h2>
                    {t(TRANSLATIONS.REPORT.key)} id: {id} ({report.type}){" "}
                  </h2>
                  <h2>{report.created_date}</h2>

                  <Flex gap="small" wrap>
                    {report.tags.map((tag, i) => (
                      <Tag color="geekblue" key={i} style={{ margin: "0 5px" }}>
                        {tag}
                      </Tag>
                    ))}
                  </Flex>
                </div>
                <h3>
                  <span>Suites: {report.data.stats.suites}</span><span> | </span>
                  <span>Tests: {report.data.stats.tests}</span><span> | </span>
                  <span style={{color: "green"}}>Passes: {report.data.stats.passes}</span><span> | </span>
                  <span style={{color: "red"}}>Failures: {report.data.stats.failures}</span>
                </h3>
              </Flex>
                  <Row style={{ marginTop: 15 }}>
                    <Col span={24}>
                      <Table
                        id="agent-teams"
                        pagination={false}
                        size="small"
                        columns={testsColumns}
                        dataSource={allTests ?? []}
                        loading={loading}
                        showSorterTooltip={true}
                        locale={{
                          triggerAsc: `${t(
                            TRANSLATIONS.SORTING_ASCENDING_LABEL.key
                          )}`,
                          triggerDesc: `${t(
                            TRANSLATIONS.SORTING_DESCENDING_LABEL.key
                          )}`,
                          cancelSort: `${t(
                            TRANSLATIONS.SORTING_CANCEL_LABEL.key
                          )}`,
                          emptyText: (
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description={`${t(
                                TRANSLATIONS.EMPTY_LIST_TITLE.key
                              )}`}
                            />
                          ),
                        }}
                        rowKey="uuid"
                      />
                    </Col>
                  </Row>

              {/* <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <hr></hr>
              <p>{JSON.stringify(report)}</p> */}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}
